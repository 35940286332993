import React from "react";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import Careers from "../../components/Careers";

function CareersPage() {
	return (
		<Layout>
			<SearchEngineOptimization title="Careers" />
			<Careers />
		</Layout>
	);
}

export default CareersPage;
