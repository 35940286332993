import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Polygon from "./polygon.svg";
import JobPostings from "./JobPostings";
import "./styles.scss";

const Careers = () => {
	const data = useStaticQuery(graphql`
		query {
			contentfulCareersPage {
				title
				whoWeAre {
					childMarkdownRemark {
						html
					}
				}
				heroBackgroundImage {
					file {
						url
					}
				}
			}
			# Fix for error with type inference in GraphQL schema when there are no postings in Contentful.
			# One entry with "Dummy Content" title is held within Contentful
			allContentfulJobPosting(filter: { title: { ne: "Dummy Content" } }) {
				edges {
					node {
						contentful_id
						title
						jobPostingDate(formatString: "MMM DD")
						type
						bambooJobLink
					}
				}
			}
		}
	`);

	const {
		contentfulCareersPage: { title, whoWeAre, heroBackgroundImage },
		allContentfulJobPosting: { edges: jobs },
	} = data;

	return (
		<div className="careers">
			<div
				className="careers__hero-bg"
				style={{ backgroundImage: `url(${heroBackgroundImage.file.url})` }}
			>
				<div className="container">
					<div className="careers__hero">
						<div>
							<h1 className="careers__title">{title}</h1>
						</div>
					</div>
				</div>
			</div>
			<section className="section section--is-spacious">
				<div className="container">
					<h2 className="careers__heading">
						Open Positions
						<div className="careers__polygon">
							<img src={Polygon} alt="" aria-hidden="true" />
						</div>
					</h2>
					{jobs?.length ? (
						<JobPostings jobs={jobs} />
					) : (
						<p>
							We're all suited up right now. If you're sure you're a Titan in
							waiting, send us a message. We still want to talk with you.
						</p>
					)}
				</div>
			</section>
			<section className="section section--is-spacious section--is-gray">
				<div className="container">
					<h2 className="careers__heading">
						Who We Are
						<div className="careers__polygon">
							<img src={Polygon} alt="" aria-hidden="true" />
						</div>
					</h2>
				</div>
				<div className="container container--is-narrow">
					{whoWeAre && (
						<div
							className="markdown"
							dangerouslySetInnerHTML={{
								__html: whoWeAre.childMarkdownRemark.html,
							}}
						/>
					)}

					<p className="careers__learn-more">
						<strong>
							<span className="text text--uppercase text--xsmall text--weight-semi-bold">
								Want to know more?
							</span>
							<br />
							<Link to="/culture" className="btn btn--black-green">
								Read about Our Culture
							</Link>
						</strong>
					</p>
				</div>
			</section>
		</div>
	);
};

export default Careers;
