import React, { FunctionComponent, useContext, useEffect } from "react";
import JobPost from "./JobPost";
import "./styles.scss";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import BREAKPOINTS from "../../utils/breakpoints";

interface JobPostingsProps {
	jobs: {
		node: {
			title: string;
			type: string;
			jobPostingDate: string;
			bambooJobLink: string;
		};
	}[];
}

const JobPostings: FunctionComponent<JobPostingsProps> = ({ jobs }) => {
	const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.LARGE.VALUE}px)`);

	if (!jobs) {
		return null;
	}

	return (
		<>
		{isDesktop ? (
			<div className="job-postings__header-row">
					<div>Position</div>
					<div>Type</div>
					<div>Post Date</div>
					{/* Empty element for alignment purposes */}
					<div></div>
				</div>
			) : (
				<div className="job-postings__header-row">
					<div>Position</div>
				</div>
			)}
			
			<ul className="job-postings">
				{jobs.map((job, index) => {
					const { title, type, jobPostingDate, bambooJobLink } = job.node;
					return (
						<JobPost
							key={index}
							title={title}
							type={type}
							jobPostingDate={jobPostingDate}
							bambooJobLink={bambooJobLink}
						/>
					);
				})}
			</ul>
		</>
	);
};

export default JobPostings;
