import React, { FunctionComponent } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import BREAKPOINTS from "../../utils/breakpoints";

interface JobPostProps {
	title: string;
	type: string;
	jobPostingDate: string;
	bambooJobLink: string;
}

const JobPost: FunctionComponent<JobPostProps> = ({
	title,
	type,
	jobPostingDate,
	bambooJobLink,
}) => {
	const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.LARGE.VALUE}px)`);

	return (
		<li className="job-postings__post">
			<div className="job-postings__post-content">
				<div className="job-postings__title">{title}</div>
				<div>{!isDesktop && <span>Type:</span>} {type}</div>
				<div>{!isDesktop && <span>Post Date:</span>} {jobPostingDate}</div>
				<div>
					<a target="_blank" rel="nofollow" href={bambooJobLink} className="btn btn--large btn--black-green">Apply Now</a>
				</div>
			</div>
		</li>
	);
};

export default JobPost;
